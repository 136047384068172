.plan-card-container {
  min-width: 350px;
}

.plan-card {
  --text-color: white;
  --icon-color: white;
  width: 350px;
}

.plan-card[data-status="PLANNING"] {
  --bg-color: #0000000a;
  --text-color: #00000040;
  --icon-color: #000000e0;
}

.plan-card[data-status="IN_REVIEW"], .plan-card[data-status="APPROVED"] {
  --bg-color: #1677ff;
}

.plan-card[data-status="REJECTED"] {
  --bg-color: #ff4d4f;
}

.plan-card .ant-card-head {
  background-color: var(--bg-color);
  color: var(--text-color);
  height: 29px;
  min-height: 29px;
  padding-right: 4px;
}

.plan-card .ant-card-head .ant-btn, .plan-card .ant-card-head .ant-btn:hover {
  color: var(--icon-color);
}

.plan-card .ant-card-head .ant-card-head-title {
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
}

.plan-card .ant-card-body {
  padding: 0 5px;
}

.plan-card .ant-card-body .ant-tabs-nav {
  padding: 0 10px;
}

.planblock {
  background-color: var(--bg-color);
  border-bottom: 1px solid var(--border-color);
  --border-color: #0000000f;
  --text-color: #1677ff;
  border-radius: 5px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 34px;
  padding: 6px 4px;
  display: flex;
}

.planblock[data-status="SUCCESS"] {
  --bg-color: #e6f4ff;
}

.planblock[data-status="WARNING"] {
  --bg-color: #fff7e6;
  --text-color: #fa8c16;
}

.planblock[data-status="ERROR"] {
  --bg-color: #fff1f0;
  --border-color: #ff4d4f;
  --text-color: #ff4d4f;
}

.planblock.disabled {
  --bg-color: #0000000a;
  --text-color: #00000040;
  --border-color: #0000000f;
}

.planblock.disabled span {
  color: #00000040;
}

.planblock.drag {
  cursor: grab;
  background-color: #fff;
  border-radius: 0;
}

.planblock.drag:active {
  cursor: grabbing;
}

.planblock.drag .ant-btn-icon, .planblock.drag .anticon {
  color: #8c8c8c;
}

.planblock .ant-btn-icon {
  color: var(--text-color);
}

.dropzone {
  color: #00000040;
  -webkit-user-select: none;
  user-select: none;
  border: 1px dashed #0000000f;
  border-radius: 5px;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding: 4px 8px;
  font-size: 10px;
  font-style: italic;
  line-height: 20px;
  display: flex;
}

.dropzone .dropzone-icon {
  background-color: #0000000a;
  border: 1px dashed #0000000f;
  border-radius: 1px;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  font-size: 22px;
  display: flex;
}

.plan-drag-tabs .ant-tabs-nav-list {
  gap: 6px;
}

.plan-drag-tabs .ant-tabs-tab {
  margin: 0 !important;
}

.plan-seance-hour {
  background-color: #0000000a;
  border-bottom: 1px dashed #0000000f;
  border-radius: 5px;
  width: 100%;
  padding: 0 10px;
  font-size: 10px;
  line-height: 22px;
}

.planning-statistic-card .ant-card-body {
  padding-top: 5px;
  padding-bottom: 5px;
}

.planning-statistic-card .ant-statistic-title {
  font-size: 12px;
}

.planning-filter .ant-btn {
  align-items: center;
  font-size: 10px;
  display: flex;
}

.plan-action-overlay .ant-dropdown-menu-submenu-expand-icon {
  display: none;
}

.custom-panel .ant-collapse-content-box {
  padding: 0 !important;
}

.ant-tabs-nav:before {
  border: none !important;
}

.fade-out {
  animation: 2s forwards fadeOut;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.gantt {
  --item-heigth: 33px;
  --day: 10px;
  --day-offset: 0;
  --total-day: 0;
  gap: 10px;
  display: flex;
}

.gantt .gannt-calendar {
  background-color: #fff;
  flex-direction: column;
  display: flex;
  position: relative;
  overflow: auto;
}

.gantt .gannt-calendar .gannt-calendar-header {
  display: flex;
}

.gantt .gannt-calendar .gannt-calendar-header .gannt-calendar-header-item {
  --day-count: 30;
  --month: calc(var(--day-count) * var(--day));
  width: var(--month);
  min-width: var(--month);
  max-width: var(--month);
  color: #fff;
  background-color: #1677ff;
  justify-content: center;
  align-items: center;
  height: 40px;
  display: flex;
  position: relative;
}

.gantt .gannt-calendar .gannt-calendar-row {
  --row-count: 1;
  height: calc(var(--row-count) * var(--item-heigth)  + 7);
  min-height: calc(var(--row-count) * var(--item-heigth)  + 40px);
  border-bottom: 1px solid #0000000f;
  width: 7610px;
  position: relative;
}

.gantt .gannt-calendar .gannt-calendar-row .gannt-calendar-item {
  --day-start: 0;
  --day-span: 0;
  --row-index: 0;
  height: 26px;
  width: calc(var(--day) * var(--day-span));
  transform: translateX(calc(var(--day) * (var(--day-start)  + var(--day-offset)))) translateY(calc(var(--item-heigth) * var(--row-index)));
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 2px;
  font-size: 12px;
  font-weight: 400;
  display: flex;
  position: absolute;
  top: 7px;
  overflow: hidden;
}

.gantt .gannt-calendar .gannt-calendar-row .gannt-calendar-item .ant-btn-icon {
  color: #69b1ff;
}

.gantt .gannt-calendar .gannt-calendar-row .gantt-calendar-item-active {
  color: #52c41a;
  background-color: #f6ffed;
  border-color: #52c41a;
}

.gantt .gannt-calendar .gannt-calendar-row .gantt-calendar-item-planning {
  color: #faad14;
  background-color: #fffbe6;
  border-style: dashed;
  border-color: #faad14;
}

.gantt .gannt-calendar .gannt-calendar-row .gantt-calendar-item-canceled {
  color: #8b8b8b;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}

.gantt .gannt-calendar .gannt-calendar-row .gantt-calendar-item-done {
  color: #69b1ff;
  background-color: #e6f4ff;
  border-color: #69b1ff;
}

.gantt .gannt-calendar .gannt-calendar-row .gantt-calendar-item-action-required {
  color: #722ed1;
  background-color: #f9f0ff;
  border-color: #722ed1;
}

.gantt .gannt-calendar .gannt-calendar-row .gantt-calendar-item-delayed-action-required {
  color: #d3adf7;
  background-color: #f9f0ff;
  border-color: #f9f0ff;
}

.gantt .gannt-calendar .gantt-calendar-divider {
  --start-day: 0;
  --left: calc(var(--start-day) * var(--day));
  width: 1px;
  height: 100%;
  left: var(--left);
  background-color: #0000000f;
  position: absolute;
}

.gantt .gannt-calendar .gantt-calendar-divider.today {
  --left: calc((var(--start-day)  + var(--day-offset)) * var(--day));
  background-color: #722ed1;
}

.gantt .gantt-top-header {
  color: #fff;
  background-color: #1677ff;
  align-items: center;
  min-width: 230px;
  height: 40px;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
}

.gantt .gannt-row-header {
  --row-count: 1;
  height: calc(var(--row-count) * var(--item-heigth)  + 7px);
  min-height: calc(var(--row-count) * var(--item-heigth)  + 40px);
  background-color: #fff;
  border-bottom: 1px solid #0000000f;
  border-radius: 5px;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
}

.gantt .highlighted {
  background-color: #f9f9f9;
}

.comment-timeline .comment-timeline-item {
  --bg-color: #f9f0ff;
  --color: #d3adf7;
  color: var(--color);
}

.comment-timeline .comment-timeline-item[data-status="PLANNING"] {
  --bg-color: #e6f4ff;
  --color: #69b1ff;
}

.comment-timeline .comment-timeline-item[data-status="IN_REVIEW"] {
  --bg-color: #fffbe6;
  --color: #ffd666;
}

.comment-timeline .comment-timeline-item[data-status="APPROVED"] {
  --bg-color: #f6ffed;
  --color: #b7eb8f;
}

.comment-timeline .comment-timeline-item[data-status="REJECTED"] {
  --bg-color: #fff1f0;
  --color: #ff7875;
}

.comment-timeline .comment-timeline-item .comment-timeline-item-icon {
  background-color: var(--bg-color);
  border-color: var(--color);
}

.class-timeline .ant-timeline-item:not(:last-child) {
  margin-bottom: 35px;
}

.class-timeline .ant-timeline-item-tail:not(:last-child) {
  height: calc(100% + 35px);
}

div.timeline_container div.ant-timeline-item-label {
  width: calc(20% - 12px) !important;
}

div.timeline_container div.ant-timeline-item-content {
  width: calc(67% - 4px) !important;
  left: calc(21% - 4px) !important;
}

div.timeline_container div.ant-timeline-item-tail, div.ant-timeline-item-head {
  left: 21% !important;
}

.flow-timeline .ant-timeline-item:not(:last-child) {
  margin-bottom: 15px;
}

.flow-timeline .ant-timeline-item-tail:not(:last-child) {
  height: calc(100% + 15px);
}

div.flow_container div.ant-timeline-item-label {
  width: calc(25% - 12px) !important;
}

div.flow_container div.ant-timeline-item-content {
  width: calc(67% - 4px) !important;
  left: calc(26% - 4px) !important;
}

div.flow_container div.ant-timeline-item-tail, div.flow_container div.ant-timeline-item-head {
  left: 26% !important;
}

.student-flow-timeline .ant-timeline-item:not(:last-child) {
  margin-bottom: 50px;
}

.student-flow-timeline .ant-timeline-item-tail:not(:last-child) {
  height: calc(100% + 24px);
}

div.student_flow_container div.ant-timeline-item-label {
  width: calc(10% - 12px) !important;
}

div.student_flow_container div.ant-timeline-item-content {
  width: calc(52% - 4px) !important;
  left: calc(11% - 4px) !important;
}

div.student_flow_container div.ant-timeline-item-tail, div.student_flow_container div.ant-timeline-item-head {
  left: 11% !important;
}

.header-menu .ant-menu-item, .header-menu .ant-menu-item-selected, .header-menu .ant-menu-item-selected:focus, .header-menu .ant-menu-item-selected:hover {
  height: 44px;
  margin: 0;
  padding: 0 20px;
  line-height: 44px;
  display: inline-block;
}

.header-menu .ant-menu-item-selected, .header-menu .ant-menu-item-selected:focus, .header-menu .ant-menu-item-selected:hover {
  overflow: hidden;
}

.header-menu.ant-menu-horizontal > .ant-menu-item:after, .header-menu.ant-menu-horizontal > .ant-menu-submenu:after {
  border-bottom: none !important;
  transition: none !important;
}

.weekly {
  background-color: #f5f5f5;
  flex-direction: column;
  display: flex;
}

.weekly-calendar {
  background-color: #e8e8e8;
  grid-template-columns: 100px repeat(7, 1fr);
  gap: 1px;
  display: grid;
}

.weekly-calendar .header {
  text-align: center;
  color: #000;
  background-color: #fff;
  border-bottom: 1px solid #d9d9d9;
  padding: 8px 0;
  font-size: 16px;
}

.weekly-calendar .time-cell {
  background-color: #fff;
  border-right: 1px solid #d9d9d9;
  justify-content: center;
  align-items: center;
  height: 60px;
  font-weight: bold;
  display: flex;
}

.weekly-calendar .time-slot {
  background-color: #fff;
  border-bottom: 1px solid #d9d9d9;
  border-right: 1px solid #d9d9d9;
  height: 60px;
  position: relative;
}

.weekly-calendar .event {
  box-sizing: border-box;
  text-align: center;
  z-index: 1;
  border: 1px solid;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
}

.weekly-calendar .event.success {
  color: #52c41a;
  background-color: #f6ffed;
  border-color: #b7eb8f;
}

.weekly-calendar .event.error {
  color: #ff4d4f;
  background-color: #fff1f0;
  border-color: #ffa39e;
}

.ant-picker-cell-selected:not(.ant-picker-cell-today) .ant-picker-cell-inner {
  background-color: #0000 !important;
}

.ant-picker-cell-selected:not(.ant-picker-cell-today) .ant-picker-cell-inner:hover {
  background-color: #0000000a !important;
}

.ant-picker-cell-selected:not(.ant-picker-cell-today) .ant-picker-cell-inner .ant-picker-calendar-date-value {
  color: #000000e0 !important;
}

.student-tab .ant-tabs-nav .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #1677ff;
}

.student-tab .ant-tabs-nav .ant-tabs-tab-active {
  background-color: #e6f4ff;
  border: 2px solid #1677ff !important;
}

html, body {
  margin: 0;
  padding: 0;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

.container {
  width: 100%;
}

@media (width >= 640px) {
  .container {
    max-width: 640px;
  }
}

@media (width >= 768px) {
  .container {
    max-width: 768px;
  }
}

@media (width >= 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (width >= 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (width >= 1536px) {
  .container {
    max-width: 1536px;
  }
}

.pointer-events-none {
  pointer-events: none;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.\!absolute {
  position: absolute !important;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.inset-x-2 {
  left: .5rem;
  right: .5rem;
}

.\!bottom-0 {
  bottom: 0 !important;
}

.\!top-auto {
  top: auto !important;
}

.-right-20 {
  right: -5rem;
}

.bottom-0 {
  bottom: 0;
}

.bottom-10 {
  bottom: 2.5rem;
}

.bottom-3 {
  bottom: .75rem;
}

.end-0 {
  inset-inline-end: 0;
}

.left-0 {
  left: 0;
}

.left-1\/2 {
  left: 50%;
}

.left-\[16px\] {
  left: 16px;
}

.right-0 {
  right: 0;
}

.right-3 {
  right: .75rem;
}

.top-0 {
  top: 0;
}

.top-1\/2 {
  top: 50%;
}

.top-3 {
  top: .75rem;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.col-span-1 {
  grid-column: span 1 / span 1;
}

.col-span-10 {
  grid-column: span 10 / span 10;
}

.col-span-11 {
  grid-column: span 11 / span 11;
}

.col-span-12 {
  grid-column: span 12 / span 12;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.col-span-3 {
  grid-column: span 3 / span 3;
}

.col-span-4 {
  grid-column: span 4 / span 4;
}

.col-span-5 {
  grid-column: span 5 / span 5;
}

.col-span-6 {
  grid-column: span 6 / span 6;
}

.col-span-8 {
  grid-column: span 8 / span 8;
}

.col-span-9 {
  grid-column: span 9 / span 9;
}

.col-span-full {
  grid-column: 1 / -1;
}

.row-span-1 {
  grid-row: span 1 / span 1;
}

.row-span-2 {
  grid-row: span 2 / span 2;
}

.\!m-0 {
  margin: 0 !important;
}

.m-0 {
  margin: 0;
}

.m-1 {
  margin: .25rem;
}

.m-2 {
  margin: .5rem;
}

.m-3 {
  margin: .75rem;
}

.mx-1 {
  margin-left: .25rem;
  margin-right: .25rem;
}

.mx-10 {
  margin-left: 2.5rem;
  margin-right: 2.5rem;
}

.mx-3 {
  margin-left: .75rem;
  margin-right: .75rem;
}

.mx-40 {
  margin-left: 10rem;
  margin-right: 10rem;
}

.mx-7 {
  margin-left: 1.75rem;
  margin-right: 1.75rem;
}

.mx-8 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.my-1 {
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.my-3 {
  margin-top: .75rem;
  margin-bottom: .75rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mb-7 {
  margin-bottom: 1.75rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.ml-0\.5 {
  margin-left: .125rem;
}

.ml-1 {
  margin-left: .25rem;
}

.ml-12 {
  margin-left: 3rem;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-3 {
  margin-left: .75rem;
}

.ml-4 {
  margin-left: 1rem;
}

.ml-5 {
  margin-left: 1.25rem;
}

.ml-6 {
  margin-left: 1.5rem;
}

.ml-7 {
  margin-left: 1.75rem;
}

.mr-1 {
  margin-right: .25rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mr-3 {
  margin-right: .75rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mr-5 {
  margin-right: 1.25rem;
}

.mr-6 {
  margin-right: 1.5rem;
}

.mr-7 {
  margin-right: 1.75rem;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-28 {
  margin-top: 7rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-7 {
  margin-top: 1.75rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-\[100px\] {
  margin-top: 100px;
}

.mt-\[14px\] {
  margin-top: 14px;
}

.mt-\[24px\] {
  margin-top: 24px;
}

.mt-auto {
  margin-top: auto;
}

.line-clamp-1 {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.line-clamp-2 {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.line-clamp-3 {
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.grid {
  display: grid;
}

.contents {
  display: contents;
}

.list-item {
  display: list-item;
}

.hidden {
  display: none;
}

.\!h-max {
  height: max-content !important;
}

.h-1\/2 {
  height: 50%;
}

.h-1\/3 {
  height: 33.3333%;
}

.h-1\/6 {
  height: 16.6667%;
}

.h-10 {
  height: 2.5rem;
}

.h-12 {
  height: 3rem;
}

.h-2 {
  height: .5rem;
}

.h-2\/3 {
  height: 66.6667%;
}

.h-2\/5 {
  height: 40%;
}

.h-2\/6 {
  height: 33.3333%;
}

.h-20 {
  height: 5rem;
}

.h-24 {
  height: 6rem;
}

.h-3 {
  height: .75rem;
}

.h-3\/6 {
  height: 50%;
}

.h-32 {
  height: 8rem;
}

.h-36 {
  height: 9rem;
}

.h-4\/6 {
  height: 66.6667%;
}

.h-40 {
  height: 10rem;
}

.h-48 {
  height: 12rem;
}

.h-5 {
  height: 1.25rem;
}

.h-5\/6 {
  height: 83.3333%;
}

.h-6 {
  height: 1.5rem;
}

.h-60 {
  height: 15rem;
}

.h-64 {
  height: 16rem;
}

.h-72 {
  height: 18rem;
}

.h-96 {
  height: 24rem;
}

.h-\[10\%\] {
  height: 10%;
}

.h-\[100\%\] {
  height: 100%;
}

.h-\[15\%\] {
  height: 15%;
}

.h-\[32px\] {
  height: 32px;
}

.h-\[390px\] {
  height: 390px;
}

.h-\[400px\] {
  height: 400px;
}

.h-\[44rem\] {
  height: 44rem;
}

.h-\[5\%\] {
  height: 5%;
}

.h-\[50\%\] {
  height: 50%;
}

.h-\[80\%\] {
  height: 80%;
}

.h-\[85\%\] {
  height: 85%;
}

.h-\[90\%\] {
  height: 90%;
}

.h-\[95\%\] {
  height: 95%;
}

.h-\[calc\(100svh-100px\)\] {
  height: calc(100svh - 100px);
}

.h-\[calc\(100svh-110px\)\] {
  height: calc(100svh - 110px);
}

.h-\[calc\(100svh-115px\)\] {
  height: calc(100svh - 115px);
}

.h-\[calc\(100svh-75px\)\] {
  height: calc(100svh - 75px);
}

.h-\[calc\(100vh-105px\)\] {
  height: calc(100vh - 105px);
}

.h-\[calc\(100vh-200px\)\] {
  height: calc(100vh - 200px);
}

.h-\[calc\(100vh-90px\)\] {
  height: calc(100vh - 90px);
}

.h-auto {
  height: auto;
}

.h-fit {
  height: fit-content;
}

.h-full {
  height: 100%;
}

.h-max {
  height: max-content;
}

.h-screen {
  height: 100vh;
}

.max-h-24 {
  max-height: 6rem;
}

.max-h-6 {
  max-height: 1.5rem;
}

.max-h-96 {
  max-height: 24rem;
}

.max-h-\[380px\] {
  max-height: 380px;
}

.max-h-\[400px\] {
  max-height: 400px;
}

.max-h-\[500px\] {
  max-height: 500px;
}

.max-h-fit {
  max-height: fit-content;
}

.max-h-full {
  max-height: 100%;
}

.max-h-min {
  max-height: min-content;
}

.min-h-10 {
  min-height: 2.5rem;
}

.min-h-5 {
  min-height: 1.25rem;
}

.min-h-\[61vh\] {
  min-height: 61vh;
}

.min-h-\[68vh\] {
  min-height: 68vh;
}

.min-h-\[90\] {
  min-height: 90px;
}

.min-h-full {
  min-height: 100%;
}

.min-h-max {
  min-height: max-content;
}

.min-h-min {
  min-height: min-content;
}

.min-h-svh {
  min-height: 100svh;
}

.\!w-max {
  width: max-content !important;
}

.w-1 {
  width: .25rem;
}

.w-1\/2 {
  width: 50%;
}

.w-1\/3 {
  width: 33.3333%;
}

.w-1\/4 {
  width: 25%;
}

.w-10 {
  width: 2.5rem;
}

.w-10\/12 {
  width: 83.3333%;
}

.w-11\/12 {
  width: 91.6667%;
}

.w-12 {
  width: 3rem;
}

.w-2\/12 {
  width: 16.6667%;
}

.w-2\/3 {
  width: 66.6667%;
}

.w-28 {
  width: 7rem;
}

.w-3 {
  width: .75rem;
}

.w-3\/12 {
  width: 25%;
}

.w-3\/4 {
  width: 75%;
}

.w-3\/6 {
  width: 50%;
}

.w-36 {
  width: 9rem;
}

.w-4\/6 {
  width: 66.6667%;
}

.w-40 {
  width: 10rem;
}

.w-48 {
  width: 12rem;
}

.w-5 {
  width: 1.25rem;
}

.w-52 {
  width: 13rem;
}

.w-6 {
  width: 1.5rem;
}

.w-64 {
  width: 16rem;
}

.w-7\/12 {
  width: 58.3333%;
}

.w-72 {
  width: 18rem;
}

.w-9\/12 {
  width: 75%;
}

.w-96 {
  width: 24rem;
}

.w-\[1px\] {
  width: 1px;
}

.w-\[200px\] {
  width: 200px;
}

.w-\[30rem\] {
  width: 30rem;
}

.w-\[32px\] {
  width: 32px;
}

.w-\[36rem\] {
  width: 36rem;
}

.w-\[500\] {
  width: 500px;
}

.w-\[610px\] {
  width: 610px;
}

.w-auto {
  width: auto;
}

.w-fit {
  width: fit-content;
}

.w-full {
  width: 100%;
}

.w-max {
  width: max-content;
}

.w-screen {
  width: 100vw;
}

.min-w-28 {
  min-width: 7rem;
}

.min-w-80 {
  min-width: 20rem;
}

.min-w-\[270px\] {
  min-width: 270px;
}

.min-w-\[32px\] {
  min-width: 32px;
}

.min-w-full {
  min-width: 100%;
}

.min-w-max {
  min-width: max-content;
}

.max-w-48 {
  max-width: 12rem;
}

.max-w-60 {
  max-width: 15rem;
}

.max-w-\[230px\] {
  max-width: 230px;
}

.max-w-\[95\%\] {
  max-width: 95%;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2 2;
}

.shrink-0 {
  flex-shrink: 0;
}

.flex-grow {
  flex-grow: 1;
}

.border-separate {
  border-collapse: separate;
}

.-translate-x-1\/2 {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-1\/2 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.cursor-ew-resize {
  cursor: ew-resize;
}

.cursor-pointer {
  cursor: pointer;
}

.select-none {
  -webkit-user-select: none;
  user-select: none;
}

.resize {
  resize: both;
}

.snap-x {
  scroll-snap-type: x var(--tw-scroll-snap-strictness);
}

.snap-mandatory {
  --tw-scroll-snap-strictness: mandatory;
}

.snap-start {
  scroll-snap-align: start;
}

.list-decimal {
  list-style-type: decimal;
}

.list-disc {
  list-style-type: disc;
}

.grid-flow-row-dense {
  grid-auto-flow: row dense;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.grid-cols-7 {
  grid-template-columns: repeat(7, minmax(0, 1fr));
}

.grid-cols-8 {
  grid-template-columns: repeat(8, minmax(0, 1fr));
}

.grid-cols-\[1fr_300px\] {
  grid-template-columns: 1fr 300px;
}

.grid-cols-\[60px_170px_1fr\] {
  grid-template-columns: 60px 170px 1fr;
}

.grid-cols-\[60px_1fr\] {
  grid-template-columns: 60px 1fr;
}

.grid-rows-2 {
  grid-template-rows: repeat(2, minmax(0, 1fr));
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.place-items-center {
  place-items: center;
}

.content-center {
  align-content: center;
}

.content-start {
  align-content: flex-start;
}

.content-between {
  align-content: space-between;
}

.items-start {
  align-items: flex-start;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.items-stretch {
  align-items: stretch;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.gap-0 {
  gap: 0;
}

.gap-0\.5 {
  gap: .125rem;
}

.gap-1 {
  gap: .25rem;
}

.gap-10 {
  gap: 2.5rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-2\.5 {
  gap: .625rem;
}

.gap-20 {
  gap: 5rem;
}

.gap-3 {
  gap: .75rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-5 {
  gap: 1.25rem;
}

.gap-7 {
  gap: 1.75rem;
}

.gap-8 {
  gap: 2rem;
}

.gap-\[10px\] {
  gap: 10px;
}

.gap-\[12px\] {
  gap: 12px;
}

.gap-\[20px\] {
  gap: 20px;
}

.gap-\[22px\] {
  gap: 22px;
}

.gap-\[3px\] {
  gap: 3px;
}

.gap-\[4px\] {
  gap: 4px;
}

.gap-\[5px\] {
  gap: 5px;
}

.gap-\[7px\] {
  gap: 7px;
}

.gap-x-1 {
  column-gap: .25rem;
}

.gap-x-10 {
  column-gap: 2.5rem;
}

.gap-x-2 {
  column-gap: .5rem;
}

.gap-x-3 {
  column-gap: .75rem;
}

.gap-x-4 {
  column-gap: 1rem;
}

.gap-x-5 {
  column-gap: 1.25rem;
}

.gap-y-1 {
  row-gap: .25rem;
}

.gap-y-2 {
  row-gap: .5rem;
}

.gap-y-4 {
  row-gap: 1rem;
}

.gap-y-5 {
  row-gap: 1.25rem;
}

.gap-y-8 {
  row-gap: 2rem;
}

.space-x-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.25rem * var(--tw-space-x-reverse));
  margin-left: calc(.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.5rem * var(--tw-space-x-reverse));
  margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.75rem * var(--tw-space-x-reverse));
  margin-left: calc(.75rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.25rem * var(--tw-space-y-reverse));
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.5rem * var(--tw-space-y-reverse));
}

.space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.75rem * var(--tw-space-y-reverse));
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.space-y-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
}

.space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}

.self-center {
  align-self: center;
}

.justify-self-center {
  justify-self: center;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-scroll {
  overflow: scroll;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.text-ellipsis {
  text-overflow: ellipsis;
}

.whitespace-normal {
  white-space: normal;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.whitespace-pre-line {
  white-space: pre-line;
}

.text-wrap {
  text-wrap: wrap;
}

.text-balance {
  text-wrap: balance;
}

.break-words {
  overflow-wrap: break-word;
}

.rounded {
  border-radius: .25rem;
}

.rounded-3xl {
  border-radius: 1.5rem;
}

.rounded-\[28px\] {
  border-radius: 28px;
}

.rounded-\[4px\] {
  border-radius: 4px;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-none {
  border-radius: 0;
}

.rounded-sm {
  border-radius: .125rem;
}

.rounded-xl {
  border-radius: .75rem;
}

.rounded-b-\[20px\] {
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}

.rounded-l-full {
  border-top-left-radius: 9999px;
  border-bottom-left-radius: 9999px;
}

.rounded-r-full {
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px;
}

.rounded-t-xl {
  border-top-left-radius: .75rem;
  border-top-right-radius: .75rem;
}

.\!border {
  border-width: 1px !important;
}

.\!border-2 {
  border-width: 2px !important;
}

.border {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-\[1px\] {
  border-width: 1px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-t {
  border-top-width: 1px;
}

.\!border-solid {
  border-style: solid !important;
}

.border-solid {
  border-style: solid;
}

.border-dashed {
  border-style: dashed;
}

.border-double {
  border-style: double;
}

.\!border-none {
  border-style: none !important;
}

.border-none {
  border-style: none;
}

.\!border-\[\#70C040\] {
  --tw-border-opacity: 1 !important;
  border-color: rgb(112 192 64 / var(--tw-border-opacity, 1)) !important;
}

.\!border-\[\#ff4d4e\] {
  --tw-border-opacity: 1 !important;
  border-color: rgb(255 77 78 / var(--tw-border-opacity, 1)) !important;
}

.\!border-green-500 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(34 197 94 / var(--tw-border-opacity, 1)) !important;
}

.\!border-red-500 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(239 68 68 / var(--tw-border-opacity, 1)) !important;
}

.border-\[\#0000000f\] {
  border-color: #0000000f;
}

.border-\[\#0000001a\] {
  border-color: #0000001a;
}

.border-\[\#00000026\] {
  border-color: #00000026;
}

.border-\[\#1677FF\] {
  --tw-border-opacity: 1;
  border-color: rgb(22 119 255 / var(--tw-border-opacity, 1));
}

.border-\[\#2F54EB\] {
  --tw-border-opacity: 1;
  border-color: rgb(47 84 235 / var(--tw-border-opacity, 1));
}

.border-\[\#52C41A\], .border-\[\#52c41a\] {
  --tw-border-opacity: 1;
  border-color: rgb(82 196 26 / var(--tw-border-opacity, 1));
}

.border-\[\#69b1ff\] {
  --tw-border-opacity: 1;
  border-color: rgb(105 177 255 / var(--tw-border-opacity, 1));
}

.border-\[\#722ed1\] {
  --tw-border-opacity: 1;
  border-color: rgb(114 46 209 / var(--tw-border-opacity, 1));
}

.border-\[\#91CAFF\] {
  --tw-border-opacity: 1;
  border-color: rgb(145 202 255 / var(--tw-border-opacity, 1));
}

.border-\[\#EB2F96\] {
  --tw-border-opacity: 1;
  border-color: rgb(235 47 150 / var(--tw-border-opacity, 1));
}

.border-\[\#F0DBFF\] {
  --tw-border-opacity: 1;
  border-color: rgb(240 219 255 / var(--tw-border-opacity, 1));
}

.border-\[\#FAAD14\] {
  --tw-border-opacity: 1;
  border-color: rgb(250 173 20 / var(--tw-border-opacity, 1));
}

.border-\[\#FF4D4F\] {
  --tw-border-opacity: 1;
  border-color: rgb(255 77 79 / var(--tw-border-opacity, 1));
}

.border-\[\#FFA39E\] {
  --tw-border-opacity: 1;
  border-color: rgb(255 163 158 / var(--tw-border-opacity, 1));
}

.border-\[\#f0f0f0\] {
  --tw-border-opacity: 1;
  border-color: rgb(240 240 240 / var(--tw-border-opacity, 1));
}

.border-\[\#f5f5f5\] {
  --tw-border-opacity: 1;
  border-color: rgb(245 245 245 / var(--tw-border-opacity, 1));
}

.border-\[\#faad14\] {
  --tw-border-opacity: 1;
  border-color: rgb(250 173 20 / var(--tw-border-opacity, 1));
}

.border-\[rgba\(0\,0\,0\,0\.15\)\] {
  border-color: #00000026;
}

.border-black {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity, 1));
}

.border-black\/10 {
  border-color: #0000001a;
}

.border-black\/15 {
  border-color: #00000026;
}

.border-blue-400 {
  --tw-border-opacity: 1;
  border-color: rgb(96 165 250 / var(--tw-border-opacity, 1));
}

.border-blue-500 {
  --tw-border-opacity: 1;
  border-color: rgb(59 130 246 / var(--tw-border-opacity, 1));
}

.border-current {
  border-color: currentColor;
}

.border-error {
  --tw-border-opacity: 1;
  border-color: rgb(255 77 79 / var(--tw-border-opacity, 1));
}

.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity, 1));
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity, 1));
}

.border-gray-400 {
  --tw-border-opacity: 1;
  border-color: rgb(156 163 175 / var(--tw-border-opacity, 1));
}

.border-green-300 {
  --tw-border-opacity: 1;
  border-color: rgb(134 239 172 / var(--tw-border-opacity, 1));
}

.border-green-500 {
  --tw-border-opacity: 1;
  border-color: rgb(34 197 94 / var(--tw-border-opacity, 1));
}

.border-green-600 {
  --tw-border-opacity: 1;
  border-color: rgb(22 163 74 / var(--tw-border-opacity, 1));
}

.border-mid-gray {
  --tw-border-opacity: 1;
  border-color: rgb(217 217 217 / var(--tw-border-opacity, 1));
}

.border-purple-200 {
  --tw-border-opacity: 1;
  border-color: rgb(233 213 255 / var(--tw-border-opacity, 1));
}

.border-purple-700 {
  --tw-border-opacity: 1;
  border-color: rgb(126 34 206 / var(--tw-border-opacity, 1));
}

.border-red-300 {
  --tw-border-opacity: 1;
  border-color: rgb(252 165 165 / var(--tw-border-opacity, 1));
}

.border-red-400 {
  --tw-border-opacity: 1;
  border-color: rgb(248 113 113 / var(--tw-border-opacity, 1));
}

.border-red-500 {
  --tw-border-opacity: 1;
  border-color: rgb(239 68 68 / var(--tw-border-opacity, 1));
}

.border-red-600 {
  --tw-border-opacity: 1;
  border-color: rgb(220 38 38 / var(--tw-border-opacity, 1));
}

.border-success {
  --tw-border-opacity: 1;
  border-color: rgb(82 196 26 / var(--tw-border-opacity, 1));
}

.border-yellow-500 {
  --tw-border-opacity: 1;
  border-color: rgb(234 179 8 / var(--tw-border-opacity, 1));
}

.\!bg-\[\#e6f4ff\] {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(230 244 255 / var(--tw-bg-opacity, 1)) !important;
}

.\!bg-\[\#f5f5f5\] {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(245 245 245 / var(--tw-bg-opacity, 1)) !important;
}

.\!bg-\[\#f6ffed\] {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(246 255 237 / var(--tw-bg-opacity, 1)) !important;
}

.\!bg-\[\#f9f0ff\] {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(249 240 255 / var(--tw-bg-opacity, 1)) !important;
}

.\!bg-\[\#fffbe6\] {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 251 230 / var(--tw-bg-opacity, 1)) !important;
}

.bg-\[\#00299A\] {
  --tw-bg-opacity: 1;
  background-color: rgb(0 41 154 / var(--tw-bg-opacity, 1));
}

.bg-\[\#1AC442\] {
  --tw-bg-opacity: 1;
  background-color: rgb(26 196 66 / var(--tw-bg-opacity, 1));
}

.bg-\[\#722ED1\] {
  --tw-bg-opacity: 1;
  background-color: rgb(114 46 209 / var(--tw-bg-opacity, 1));
}

.bg-\[\#91CAFF\] {
  --tw-bg-opacity: 1;
  background-color: rgb(145 202 255 / var(--tw-bg-opacity, 1));
}

.bg-\[\#C5EE90\] {
  --tw-bg-opacity: 1;
  background-color: rgb(197 238 144 / var(--tw-bg-opacity, 1));
}

.bg-\[\#D3ADF7\] {
  --tw-bg-opacity: 1;
  background-color: rgb(211 173 247 / var(--tw-bg-opacity, 1));
}

.bg-\[\#DADADA\] {
  --tw-bg-opacity: 1;
  background-color: rgb(218 218 218 / var(--tw-bg-opacity, 1));
}

.bg-\[\#E6F4FF\] {
  --tw-bg-opacity: 1;
  background-color: rgb(230 244 255 / var(--tw-bg-opacity, 1));
}

.bg-\[\#E8FFC9\] {
  --tw-bg-opacity: 1;
  background-color: rgb(232 255 201 / var(--tw-bg-opacity, 1));
}

.bg-\[\#F0F5FF\] {
  --tw-bg-opacity: 1;
  background-color: rgb(240 245 255 / var(--tw-bg-opacity, 1));
}

.bg-\[\#F4E5FF\] {
  --tw-bg-opacity: 1;
  background-color: rgb(244 229 255 / var(--tw-bg-opacity, 1));
}

.bg-\[\#F5F5F5\] {
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 245 / var(--tw-bg-opacity, 1));
}

.bg-\[\#F6FFED\] {
  --tw-bg-opacity: 1;
  background-color: rgb(246 255 237 / var(--tw-bg-opacity, 1));
}

.bg-\[\#F9F0FF\] {
  --tw-bg-opacity: 1;
  background-color: rgb(249 240 255 / var(--tw-bg-opacity, 1));
}

.bg-\[\#FCF8FF\] {
  --tw-bg-opacity: 1;
  background-color: rgb(252 248 255 / var(--tw-bg-opacity, 1));
}

.bg-\[\#FFA39E\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 163 158 / var(--tw-bg-opacity, 1));
}

.bg-\[\#FFADD2\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 173 210 / var(--tw-bg-opacity, 1));
}

.bg-\[\#FFF0F6\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 240 246 / var(--tw-bg-opacity, 1));
}

.bg-\[\#FFF1F0\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 241 240 / var(--tw-bg-opacity, 1));
}

.bg-\[\#FFFBE6\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 251 230 / var(--tw-bg-opacity, 1));
}

.bg-\[\#FFFEFE\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 254 254 / var(--tw-bg-opacity, 1));
}

.bg-\[\#FFFFFF\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}

.bg-\[\#e6f4ff\] {
  --tw-bg-opacity: 1;
  background-color: rgb(230 244 255 / var(--tw-bg-opacity, 1));
}

.bg-\[\#e8ffd3\] {
  --tw-bg-opacity: 1;
  background-color: rgb(232 255 211 / var(--tw-bg-opacity, 1));
}

.bg-\[\#f3e2ff\] {
  --tw-bg-opacity: 1;
  background-color: rgb(243 226 255 / var(--tw-bg-opacity, 1));
}

.bg-\[\#f6ffed\] {
  --tw-bg-opacity: 1;
  background-color: rgb(246 255 237 / var(--tw-bg-opacity, 1));
}

.bg-\[\#f8f0ff\] {
  --tw-bg-opacity: 1;
  background-color: rgb(248 240 255 / var(--tw-bg-opacity, 1));
}

.bg-\[\#fff1f0\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 241 240 / var(--tw-bg-opacity, 1));
}

.bg-\[\#fffbe6\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 251 230 / var(--tw-bg-opacity, 1));
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity, 1));
}

.bg-black\/10 {
  background-color: #0000001a;
}

.bg-blue-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(219 234 254 / var(--tw-bg-opacity, 1));
}

.bg-blue-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(191 219 254 / var(--tw-bg-opacity, 1));
}

.bg-blue-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(239 246 255 / var(--tw-bg-opacity, 1));
}

.bg-blue-50\/\[\.6\] {
  background-color: #eff6ff99;
}

.bg-blue-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity, 1));
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity, 1));
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity, 1));
}

.bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity, 1));
}

.bg-gray-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(156 163 175 / var(--tw-bg-opacity, 1));
}

.bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity, 1));
}

.bg-green-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(220 252 231 / var(--tw-bg-opacity, 1));
}

.bg-green-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(187 247 208 / var(--tw-bg-opacity, 1));
}

.bg-green-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(134 239 172 / var(--tw-bg-opacity, 1));
}

.bg-green-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(74 222 128 / var(--tw-bg-opacity, 1));
}

.bg-green-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(240 253 244 / var(--tw-bg-opacity, 1));
}

.bg-green-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(34 197 94 / var(--tw-bg-opacity, 1));
}

.bg-light-error {
  --tw-bg-opacity: 1;
  background-color: rgb(255 241 240 / var(--tw-bg-opacity, 1));
}

.bg-light-success {
  --tw-bg-opacity: 1;
  background-color: rgb(246 255 237 / var(--tw-bg-opacity, 1));
}

.bg-purple-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(250 245 255 / var(--tw-bg-opacity, 1));
}

.bg-red-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 226 226 / var(--tw-bg-opacity, 1));
}

.bg-red-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(248 113 113 / var(--tw-bg-opacity, 1));
}

.bg-red-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 242 242 / var(--tw-bg-opacity, 1));
}

.bg-slate-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(203 213 225 / var(--tw-bg-opacity, 1));
}

.bg-transparent {
  background-color: #0000;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}

.bg-white\/40 {
  background-color: #fff6;
}

.bg-yellow-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 252 232 / var(--tw-bg-opacity, 1));
}

.object-scale-down {
  object-fit: scale-down;
}

.\!p-0 {
  padding: 0 !important;
}

.\!p-2 {
  padding: .5rem !important;
}

.\!p-4 {
  padding: 1rem !important;
}

.p-0 {
  padding: 0;
}

.p-0\.5 {
  padding: .125rem;
}

.p-1 {
  padding: .25rem;
}

.p-10 {
  padding: 2.5rem;
}

.p-2 {
  padding: .5rem;
}

.p-3 {
  padding: .75rem;
}

.p-4 {
  padding: 1rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-\[5px\] {
  padding: 5px;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.px-\[10px\] {
  padding-left: 10px;
  padding-right: 10px;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-\[4px\] {
  padding-top: 4px;
  padding-bottom: 4px;
}

.py-\[6px\] {
  padding-top: 6px;
  padding-bottom: 6px;
}

.pb-0 {
  padding-bottom: 0;
}

.pb-1 {
  padding-bottom: .25rem;
}

.pb-10 {
  padding-bottom: 2.5rem;
}

.pb-2 {
  padding-bottom: .5rem;
}

.pb-3 {
  padding-bottom: .75rem;
}

.pl-10 {
  padding-left: 2.5rem;
}

.pl-2 {
  padding-left: .5rem;
}

.pl-3 {
  padding-left: .75rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pl-5 {
  padding-left: 1.25rem;
}

.pl-8 {
  padding-left: 2rem;
}

.pr-1 {
  padding-right: .25rem;
}

.pr-10 {
  padding-right: 2.5rem;
}

.pr-2 {
  padding-right: .5rem;
}

.pt-0 {
  padding-top: 0;
}

.pt-1 {
  padding-top: .25rem;
}

.pt-10 {
  padding-top: 2.5rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pt-3 {
  padding-top: .75rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-5 {
  padding-top: 1.25rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.pt-9 {
  padding-top: 2.25rem;
}

.pt-\[5px\] {
  padding-top: 5px;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.text-start {
  text-align: start;
}

.align-middle {
  vertical-align: middle;
}

.font-mono {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
}

.font-sans {
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-6xl {
  font-size: 3.75rem;
  line-height: 1;
}

.text-\[10px\] {
  font-size: 10px;
}

.text-\[12px\] {
  font-size: 12px;
}

.text-\[16px\] {
  font-size: 16px;
}

.text-\[9px\] {
  font-size: 9px;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.font-light {
  font-weight: 300;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.italic {
  font-style: italic;
}

.tracking-wide {
  letter-spacing: .025em;
}

.tracking-wider {
  letter-spacing: .05em;
}

.text-\[\#00000073\] {
  color: #00000073;
}

.text-\[\#1677FF\], .text-\[\#1677ff\] {
  --tw-text-opacity: 1;
  color: rgb(22 119 255 / var(--tw-text-opacity, 1));
}

.text-\[\#1777FF\] {
  --tw-text-opacity: 1;
  color: rgb(23 119 255 / var(--tw-text-opacity, 1));
}

.text-\[\#3F0A70\] {
  --tw-text-opacity: 1;
  color: rgb(63 10 112 / var(--tw-text-opacity, 1));
}

.text-\[\#4096FF\] {
  --tw-text-opacity: 1;
  color: rgb(64 150 255 / var(--tw-text-opacity, 1));
}

.text-\[\#52C41A\], .text-\[\#52c41a\] {
  --tw-text-opacity: 1;
  color: rgb(82 196 26 / var(--tw-text-opacity, 1));
}

.text-\[\#69b1ff\] {
  --tw-text-opacity: 1;
  color: rgb(105 177 255 / var(--tw-text-opacity, 1));
}

.text-\[\#722ED1\], .text-\[\#722ed1\] {
  --tw-text-opacity: 1;
  color: rgb(114 46 209 / var(--tw-text-opacity, 1));
}

.text-\[\#8b8b8b\] {
  --tw-text-opacity: 1;
  color: rgb(139 139 139 / var(--tw-text-opacity, 1));
}

.text-\[\#8d7473\] {
  --tw-text-opacity: 1;
  color: rgb(141 116 115 / var(--tw-text-opacity, 1));
}

.text-\[\#90e269\] {
  --tw-text-opacity: 1;
  color: rgb(144 226 105 / var(--tw-text-opacity, 1));
}

.text-\[\#999999\] {
  --tw-text-opacity: 1;
  color: rgb(153 153 153 / var(--tw-text-opacity, 1));
}

.text-\[\#D3ADF7\] {
  --tw-text-opacity: 1;
  color: rgb(211 173 247 / var(--tw-text-opacity, 1));
}

.text-\[\#EB2F96\] {
  --tw-text-opacity: 1;
  color: rgb(235 47 150 / var(--tw-text-opacity, 1));
}

.text-\[\#F5222D\] {
  --tw-text-opacity: 1;
  color: rgb(245 34 45 / var(--tw-text-opacity, 1));
}

.text-\[\#FF4C58\] {
  --tw-text-opacity: 1;
  color: rgb(255 76 88 / var(--tw-text-opacity, 1));
}

.text-\[\#c1c1c1\] {
  --tw-text-opacity: 1;
  color: rgb(193 193 193 / var(--tw-text-opacity, 1));
}

.text-\[\#d3adf7\] {
  --tw-text-opacity: 1;
  color: rgb(211 173 247 / var(--tw-text-opacity, 1));
}

.text-\[\#faad14\] {
  --tw-text-opacity: 1;
  color: rgb(250 173 20 / var(--tw-text-opacity, 1));
}

.text-\[\#ff8d87\] {
  --tw-text-opacity: 1;
  color: rgb(255 141 135 / var(--tw-text-opacity, 1));
}

.text-\[\#ffffff\] {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity, 1));
}

.text-black\/45 {
  color: #00000073;
}

.text-black\/50 {
  color: #00000080;
}

.text-blue-400 {
  --tw-text-opacity: 1;
  color: rgb(96 165 250 / var(--tw-text-opacity, 1));
}

.text-blue-500 {
  --tw-text-opacity: 1;
  color: rgb(59 130 246 / var(--tw-text-opacity, 1));
}

.text-custom-purple {
  --tw-text-opacity: 1;
  color: rgb(114 46 209 / var(--tw-text-opacity, 1));
}

.text-cyan-600 {
  --tw-text-opacity: 1;
  color: rgb(8 145 178 / var(--tw-text-opacity, 1));
}

.text-dark-gray {
  --tw-text-opacity: 1;
  color: rgb(174 174 174 / var(--tw-text-opacity, 1));
}

.text-error {
  --tw-text-opacity: 1;
  color: rgb(255 77 79 / var(--tw-text-opacity, 1));
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity, 1));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity, 1));
}

.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity, 1));
}

.text-green-300 {
  --tw-text-opacity: 1;
  color: rgb(134 239 172 / var(--tw-text-opacity, 1));
}

.text-green-500 {
  --tw-text-opacity: 1;
  color: rgb(34 197 94 / var(--tw-text-opacity, 1));
}

.text-green-600 {
  --tw-text-opacity: 1;
  color: rgb(22 163 74 / var(--tw-text-opacity, 1));
}

.text-light-purple {
  --tw-text-opacity: 1;
  color: rgb(211 173 247 / var(--tw-text-opacity, 1));
}

.text-orange-400 {
  --tw-text-opacity: 1;
  color: rgb(251 146 60 / var(--tw-text-opacity, 1));
}

.text-purple-300 {
  --tw-text-opacity: 1;
  color: rgb(216 180 254 / var(--tw-text-opacity, 1));
}

.text-purple-400 {
  --tw-text-opacity: 1;
  color: rgb(192 132 252 / var(--tw-text-opacity, 1));
}

.text-purple-700 {
  --tw-text-opacity: 1;
  color: rgb(126 34 206 / var(--tw-text-opacity, 1));
}

.text-red-300 {
  --tw-text-opacity: 1;
  color: rgb(252 165 165 / var(--tw-text-opacity, 1));
}

.text-red-400 {
  --tw-text-opacity: 1;
  color: rgb(248 113 113 / var(--tw-text-opacity, 1));
}

.text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity, 1));
}

.text-red-600 {
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity, 1));
}

.text-selected-color {
  --tw-text-opacity: 1;
  color: rgb(22 119 255 / var(--tw-text-opacity, 1));
}

.text-success {
  --tw-text-opacity: 1;
  color: rgb(82 196 26 / var(--tw-text-opacity, 1));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.text-yellow-500 {
  --tw-text-opacity: 1;
  color: rgb(234 179 8 / var(--tw-text-opacity, 1));
}

.underline {
  text-decoration-line: underline;
}

.opacity-45 {
  opacity: .45;
}

.opacity-50 {
  opacity: .5;
}

.opacity-55 {
  opacity: .55;
}

.opacity-60 {
  opacity: .6;
}

.opacity-70 {
  opacity: .7;
}

.opacity-75 {
  opacity: .75;
}

.opacity-90 {
  opacity: .9;
}

.shadow-2xl {
  --tw-shadow: 0 25px 50px -12px #00000040;
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-none {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-black {
  --tw-shadow-color: #000;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-light-error {
  --tw-shadow-color: #fff1f0;
  --tw-shadow: var(--tw-shadow-colored);
}

.outline-none {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.outline {
  outline-style: solid;
}

.outline-1 {
  outline-width: 1px;
}

.outline-8 {
  outline-width: 8px;
}

.outline-offset-1 {
  outline-offset: 1px;
}

.outline-gray-100 {
  outline-color: #f3f4f6;
}

.outline-gray-300 {
  outline-color: #d1d5db;
}

.outline-white {
  outline-color: #fff;
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.backdrop-blur-sm {
  --tw-backdrop-blur: blur(4px);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.\!transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke !important;
  transition-duration: .15s !important;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.scrollbar-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

pre {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif;
}

.teacher-border .ant-tabs-ink-bar {
  background-color: #f9541c;
}

.ant-upload.ant-upload-select {
  width: 100%;
}

.ant-table-thead th {
  text-align: center !important;
}

:where(.css-dev-only-do-not-override-k83k30).ant-table-wrapper .ant-table.ant-table-small {
  font-size: 12px;
}

.ant-table-tbody tr:nth-child(2n) {
  background-color: #f6f6f6;
}

table td, table th {
  border-inline-end-color: #e3e3e3 !important;
  border-bottom-color: #e3e3e3 !important;
  border-inline-start-color: #e3e3e3 !important;
  border-top-color: #e3e3e3 !important;
}

.ant-table-body {
  overflow-y: auto !important;
}

:where(.css-dev-only-do-not-override-k83k30).ant-layout {
  background: #f8f8f8 !important;
}

.ant-table-container {
  border: 1px solid #d5d5d5 !important;
}

.help-right .ant-form-item-explain {
  text-align: right;
  padding-top: 6px;
}

.meta-avatar-padding .ant-card-meta-avatar {
  padding: 4px;
}

.ant-list-footer {
  border-top: 2px solid #ebebeb;
}

.calender-small-text input {
  font-size: 12px !important;
}

.exercise-message-container {
  width: 100%;
}

.exercise-message-container .exercise-message {
  align-items: end;
  gap: 4px;
  width: 100%;
  display: flex;
}

.exercise-message-container .exercise-message .exercise-message-text {
  background-color: #e6f4ff;
  border: 1px solid #1677ff;
  border-radius: 8px;
  width: 100%;
  padding: 8px;
  font-size: large;
}

.exercise-message-container .exercise-message .exercise-message-question {
  color: #722ed1;
  text-align: center;
  background-color: #fff;
  border: 1px dashed #1677ff;
  border-radius: 8px;
  width: 100%;
  padding: 8px;
  font-size: x-large;
}

.exercise-message-container[data-main-member="true"] {
  padding-left: 50px;
}

.exercise-message-container[data-main-member="true"] .exercise-message {
  flex-direction: row-reverse;
}

.exercise-message-container[data-main-member="true"] .exercise-message .exercise-message-text {
  border-bottom-right-radius: 0;
}

.exercise-message-container[data-main-member="false"] {
  padding-right: 50px;
}

.exercise-message-container[data-main-member="false"] .exercise-message {
  flex-direction: row;
}

.exercise-message-container[data-main-member="false"] .exercise-message .exercise-message-text {
  border-bottom-left-radius: 0;
}

.class-carousel .ant-carousel .slick-dots li button, .class-carousel .ant-carousel .slick-dots .slick-active button {
  background-color: #00f;
}

.hover\:line-clamp-4:hover {
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.hover\:border-2:hover {
  border-width: 2px;
}

.hover\:\!border-none:hover {
  border-style: none !important;
}

.hover\:\!border-\[\#52C41A\]:hover {
  --tw-border-opacity: 1 !important;
  border-color: rgb(82 196 26 / var(--tw-border-opacity, 1)) !important;
}

.hover\:\!border-\[\#722ED1\]:hover, .hover\:\!border-\[\#722ed1\]:hover {
  --tw-border-opacity: 1 !important;
  border-color: rgb(114 46 209 / var(--tw-border-opacity, 1)) !important;
}

.hover\:\!border-\[\#FF4D4F\]:hover {
  --tw-border-opacity: 1 !important;
  border-color: rgb(255 77 79 / var(--tw-border-opacity, 1)) !important;
}

.hover\:\!border-gray-500:hover {
  --tw-border-opacity: 1 !important;
  border-color: rgb(107 114 128 / var(--tw-border-opacity, 1)) !important;
}

.hover\:\!border-purple-500:hover {
  --tw-border-opacity: 1 !important;
  border-color: rgb(168 85 247 / var(--tw-border-opacity, 1)) !important;
}

.hover\:border-\[\#1677FF\]:hover {
  --tw-border-opacity: 1;
  border-color: rgb(22 119 255 / var(--tw-border-opacity, 1));
}

.hover\:border-\[\#91CAFF\]:hover {
  --tw-border-opacity: 1;
  border-color: rgb(145 202 255 / var(--tw-border-opacity, 1));
}

.hover\:border-\[\#E6F4FF\]:hover {
  --tw-border-opacity: 1;
  border-color: rgb(230 244 255 / var(--tw-border-opacity, 1));
}

.hover\:border-\[\#EC3196\]:hover {
  --tw-border-opacity: 1;
  border-color: rgb(236 49 150 / var(--tw-border-opacity, 1));
}

.hover\:border-\[\#bb76ec\]:hover {
  --tw-border-opacity: 1;
  border-color: rgb(187 118 236 / var(--tw-border-opacity, 1));
}

.hover\:\!bg-\[\#722ED1\]:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(114 46 209 / var(--tw-bg-opacity, 1)) !important;
}

.hover\:\!bg-\[\#F6FFED\]:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(246 255 237 / var(--tw-bg-opacity, 1)) !important;
}

.hover\:\!bg-\[\#FFF1F0\]:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 241 240 / var(--tw-bg-opacity, 1)) !important;
}

.hover\:\!bg-\[\#e6f4ff\]:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(230 244 255 / var(--tw-bg-opacity, 1)) !important;
}

.hover\:\!bg-\[\#f5f5f5\]:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(245 245 245 / var(--tw-bg-opacity, 1)) !important;
}

.hover\:\!bg-\[\#f6ffed\]:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(246 255 237 / var(--tw-bg-opacity, 1)) !important;
}

.hover\:\!bg-\[\#f9f0ff\]:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(249 240 255 / var(--tw-bg-opacity, 1)) !important;
}

.hover\:\!bg-\[\#fffbe6\]:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 251 230 / var(--tw-bg-opacity, 1)) !important;
}

.hover\:\!bg-gray-400:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(156 163 175 / var(--tw-bg-opacity, 1)) !important;
}

.hover\:\!bg-green-600:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(22 163 74 / var(--tw-bg-opacity, 1)) !important;
}

.hover\:\!bg-purple-400:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(192 132 252 / var(--tw-bg-opacity, 1)) !important;
}

.hover\:\!bg-red-600:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(220 38 38 / var(--tw-bg-opacity, 1)) !important;
}

.hover\:bg-\[\#E6F4FF\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(230 244 255 / var(--tw-bg-opacity, 1));
}

.hover\:bg-\[\#EC3196\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(236 49 150 / var(--tw-bg-opacity, 1));
}

.hover\:bg-gray-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity, 1));
}

.hover\:bg-white:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}

.hover\:\!text-\[\#c1c1c1\]:hover {
  --tw-text-opacity: 1 !important;
  color: rgb(193 193 193 / var(--tw-text-opacity, 1)) !important;
}

.hover\:\!text-error:hover {
  --tw-text-opacity: 1 !important;
  color: rgb(255 77 79 / var(--tw-text-opacity, 1)) !important;
}

.hover\:\!text-success:hover {
  --tw-text-opacity: 1 !important;
  color: rgb(82 196 26 / var(--tw-text-opacity, 1)) !important;
}

.hover\:\!text-white:hover {
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1)) !important;
}

.hover\:text-\[\#1677FF\]:hover {
  --tw-text-opacity: 1;
  color: rgb(22 119 255 / var(--tw-text-opacity, 1));
}

.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.hover\:underline:hover {
  text-decoration-line: underline;
}

.disabled\:\!border-solid:disabled {
  border-style: solid !important;
}

.disabled\:\!border-dashed:disabled {
  border-style: dashed !important;
}

.disabled\:\!border-\[\#52c41a\]:disabled {
  --tw-border-opacity: 1 !important;
  border-color: rgb(82 196 26 / var(--tw-border-opacity, 1)) !important;
}

.disabled\:\!border-\[\#69b1ff\]:disabled {
  --tw-border-opacity: 1 !important;
  border-color: rgb(105 177 255 / var(--tw-border-opacity, 1)) !important;
}

.disabled\:\!border-\[\#722ed1\]:disabled {
  --tw-border-opacity: 1 !important;
  border-color: rgb(114 46 209 / var(--tw-border-opacity, 1)) !important;
}

.disabled\:\!border-\[\#ac46f0\]:disabled {
  --tw-border-opacity: 1 !important;
  border-color: rgb(172 70 240 / var(--tw-border-opacity, 1)) !important;
}

.disabled\:\!border-\[\#f5f5f5\]:disabled {
  --tw-border-opacity: 1 !important;
  border-color: rgb(245 245 245 / var(--tw-border-opacity, 1)) !important;
}

.disabled\:\!border-\[\#faad14\]:disabled {
  --tw-border-opacity: 1 !important;
  border-color: rgb(250 173 20 / var(--tw-border-opacity, 1)) !important;
}

.disabled\:\!bg-\[\#e6f4ff\]:disabled {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(230 244 255 / var(--tw-bg-opacity, 1)) !important;
}

.disabled\:\!bg-\[\#f5f5f5\]:disabled {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(245 245 245 / var(--tw-bg-opacity, 1)) !important;
}

.disabled\:\!bg-\[\#f6ffed\]:disabled {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(246 255 237 / var(--tw-bg-opacity, 1)) !important;
}

.disabled\:\!bg-\[\#f9f0ff\]:disabled {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(249 240 255 / var(--tw-bg-opacity, 1)) !important;
}

.disabled\:\!bg-\[\#fffbe6\]:disabled {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 251 230 / var(--tw-bg-opacity, 1)) !important;
}

.disabled\:\!text-\[\#52c41a\]:disabled {
  --tw-text-opacity: 1 !important;
  color: rgb(82 196 26 / var(--tw-text-opacity, 1)) !important;
}

.disabled\:\!text-\[\#69b1ff\]:disabled {
  --tw-text-opacity: 1 !important;
  color: rgb(105 177 255 / var(--tw-text-opacity, 1)) !important;
}

.disabled\:\!text-\[\#722ed1\]:disabled {
  --tw-text-opacity: 1 !important;
  color: rgb(114 46 209 / var(--tw-text-opacity, 1)) !important;
}

.disabled\:\!text-\[\#8b8b8b\]:disabled {
  --tw-text-opacity: 1 !important;
  color: rgb(139 139 139 / var(--tw-text-opacity, 1)) !important;
}

.disabled\:\!text-\[\#d3adf7\]:disabled {
  --tw-text-opacity: 1 !important;
  color: rgb(211 173 247 / var(--tw-text-opacity, 1)) !important;
}

.disabled\:\!text-\[\#faad14\]:disabled {
  --tw-text-opacity: 1 !important;
  color: rgb(250 173 20 / var(--tw-text-opacity, 1)) !important;
}

@media (width >= 640px) {
  .sm\:ml-4 {
    margin-left: 1rem;
  }

  .sm\:grid {
    display: grid;
  }

  .sm\:h-\[430px\] {
    height: 430px;
  }

  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .sm\:gap-2 {
    gap: .5rem;
  }
}

@media (width >= 768px) {
  .md\:line-clamp-2 {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
  }

  .md\:flex {
    display: flex;
  }

  .md\:grid {
    display: grid;
  }

  .md\:h-3\/6 {
    height: 50%;
  }

  .md\:h-4\/6 {
    height: 66.6667%;
  }

  .md\:h-\[430px\] {
    height: 430px;
  }

  .md\:h-\[450px\] {
    height: 450px;
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .md\:flex-col {
    flex-direction: column;
  }

  .md\:gap-2 {
    gap: .5rem;
  }

  .md\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .md\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .md\:text-xs {
    font-size: .75rem;
    line-height: 1rem;
  }
}

@media (width >= 1024px) {
  .lg\:grid {
    display: grid;
  }

  .lg\:h-2\/6 {
    height: 33.3333%;
  }

  .lg\:h-4\/6 {
    height: 66.6667%;
  }

  .lg\:h-\[430px\] {
    height: 430px;
  }

  .lg\:h-full {
    height: 100%;
  }

  .lg\:min-h-max {
    min-height: max-content;
  }

  .lg\:min-w-max {
    min-width: max-content;
  }

  .lg\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .lg\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .lg\:gap-2 {
    gap: .5rem;
  }

  .lg\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .lg\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .lg\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .lg\:text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .lg\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}

@media (width >= 1280px) {
  .xl\:flex {
    display: flex;
  }

  .xl\:grid {
    display: grid;
  }

  .xl\:h-\[310px\] {
    height: 310px;
  }

  .xl\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .xl\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .xl\:justify-between {
    justify-content: space-between;
  }

  .xl\:gap-4 {
    gap: 1rem;
  }

  .xl\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .xl\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .xl\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .xl\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}

@media (width >= 1536px) {
  .\32 xl\:max-h-\[480px\] {
    max-height: 480px;
  }

  .\32 xl\:max-h-\[600px\] {
    max-height: 600px;
  }

  .\32 xl\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .\32 xl\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .\32 xl\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .\32 xl\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}
/*# sourceMappingURL=index.ac4e64c4.css.map */
